<template>
  <div class="map-chart-container">
    <!-- <div class="map-bg" ref="mapBg"></div> -->
    <div class="map" :id="id">
      <!-- 地图 -->
    </div>
    <div class="mark-item-container" v-show="show" ref="box">
      <div class="box-title">XXX林长</div>
      <div class="mark-item">
        <div class="mark-item-label">编码：</div>
        <div class="mark-item-val">
          FP41003
        </div>
      </div>
      <div class="mark-item">
        <div class="mark-item-label">类型：</div>
        <div class="mark-item-val">
          FP
        </div>
      </div>

      <div class="mark-item">
        <div class="mark-item-label">级别：</div>
        <div class="mark-item-val">
          国家级
        </div>
      </div>

      <div class="mark-item">
        <div class="mark-item-label">行政区划：</div>
        <div class="mark-item-val">
          河南平顶山汝州市
        </div>
      </div>

      <div class="mark-item">
        <div class="mark-item-label">面积（公顷）：</div>
        <div class="mark-item-val">
          5375.83
        </div>
      </div>

      <div class="mark-item">
        <div class="mark-item-label">批复面积：</div>
        <div class="mark-item-val">
          4900.37
        </div>
      </div>

      <div class="mark-item">
        <div class="mark-item-label">批复时间：</div>
        <div class="mark-item-val">
          -
        </div>
      </div>

      <div class="mark-item">
        <div class="mark-item-label">批准文号：</div>
        <div class="mark-item-val">
          林造批字【1992
        </div>
      </div>

      <div class="mark-item">
        <div class="mark-item-label">调整批复文：</div>
        <div class="mark-item-val">
          -
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mapJson from "@/assets/mapJson/henansheng.json";
import cityMap from '@/assets/mapJson/city'
import point1 from '@/assets/images/point2.jpg'
import point2 from '@/assets/images/point1.jpg'
import mapBg from '@/assets/images/map-bg1.jpg'
// import {mapBg} from '@/assets/js/mapBg'

export default {
  name: "MapChart",
  props: {
    id: {
      type: String,
      default: function () {
        return "map-chart-" + this.$utils.uuid();
      },
    },
  },
  data() {
    return {
      show:false,
      mapData: [
        {
          name: "濮阳市",
          code: "pys",
          value: 20057,
          lz: 23,
          hly: 333,
          wgy: 3333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [180, 170, 280, 270],
            [180, 280, 350, 340],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "安阳市",
          code: "ays",
          value: 1547,
          lz: 22,
          hly: 3333,
          wgy: 31333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 370, 280, 270],
            [180, 80, 50, 40],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "鹤壁市",
          code: "hbs",
          value: 31686,
          lz: 233,
          hly: 1333,
          wgy: 3333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 270, 180, 170],
            [280, 180, 150, 140],
          ],
          tableData: [{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "新乡市",
          code: "xxs",
          value: 6992,
          lz: 63,
          hly: 323,
          wgy: 3373,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [180, 270, 180, 170],
            [280, 380, 150, 140],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },]
        },
        {
          name: "焦作市",
          code: "jzs",
          value: 44045,
          lz: 73,
          hly: 323,
          wgy: 6333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 270, 280, 170],
            [280, 380, 150, 140],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "济源市",
          code: "jys",
          value: 40689,
          lz: 83,
          hly: 233,
          wgy: 4333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 270, 180, 370],
            [280, 180, 150, 240],
          ],
          tableData: [{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "三门峡市",
          code: "smxs",
          value: 37659,
          lz: 93,
          hly: 133,
          wgy: 1333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [180, 270, 180, 170],
            [380, 180, 150, 140],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "洛阳市",
          code: "lys",
          value: 45180,
          lz: 123,
          hly: 1333,
          wgy: 13333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 170, 180, 170],
            [280, 380, 150, 140],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },]
        },
        {
          name: "郑州市",
          code: "zzs",
          value: 55204,
          lz: 73,
          hly: 3343,
          wgy: 32333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 270, 180, 270],
            [280, 180, 150, 340],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "开封市",
          code: "kfs",
          value: 21900,
          lz: 93,
          hly: 333,
          wgy: 3333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 270, 280, 170],
            [280, 380, 150, 140],
          ],
          tableData: [{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "商丘市",
          code: "sqs",
          value: 4918,
          lz: 123,
          hly: 333,
          wgy: 3333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 370, 180, 170],
            [280, 80, 150, 140],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "平顶山市",
          code: "pdss",
          lz: 21,
          hly: 45,
          wgy: 121,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 270, 180, 170],
            [280, 180, 150, 140],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "许昌市",
          code: "xcs",
          value: 2881,
          lz: 423,
          hly: 4333,
          wgy: 3333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 270, 180, 370],
            [280, 180, 150, 140],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },]
        },
        {
          name: "周口市",
          code: "zks",
          value: 9881,
          lz: 53,
          hly: 303,
          wgy: 3234,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 70, 180, 170],
            [280, 180, 150, 140],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "漯河市",
          code: "lhs",
          value: 1881,
          lz: 213,
          hly: 3133,
          wgy: 33133,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 270, 80, 170],
            [280, 180, 150, 140],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "南阳市",
          code: "nys",
          value: 881,
          lz: 273,
          hly: 7333,
          wgy: 33733,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 70, 180, 170],
            [280, 180, 150, 40],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "驻马店市",
          code: "zmds",
          value: 1981,
          lz: 223,
          hly: 3333,
          wgy: 23333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [380, 270, 80, 170],
            [280, 180, 150, 140],
          ],
          tableData: [{
            region: '区域1',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 12
          },{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
        {
          name: "信阳市",
          code: "xys",
          value: 2881,
          lz: 123,
          hly: 7333,
          wgy: 73333,
          xaxis: ["宝丰县","鲁山县","郊县","叶县"],
          dataArr: [
            [80, 270, 180, 170],
            [280, 180, 150, 140],
          ],
          tableData: [{
            region: '区域2',
            num: 3,
            area: 400,
            lz: 2,
            hly: 5,
            wgy: 12
          },{
            region: '区域3',
            num: 9,
            area: 1200,
            lz: 2,
            hly: 5,
            wgy: 121
          },{
            region: '区域4',
            num: 2,
            area: 100,
            lz: 1,
            hly: 9,
            wgy: 12
          },{
            region: '区域5',
            num: 5,
            area: 200,
            lz: 1,
            hly: 51,
            wgy: 12
          },{
            region: '区域6',
            num: 5,
            area: 200,
            lz: 1,
            hly: 5,
            wgy: 121
          },{
            region: '区域7',
            num: 5,
            area: 200,
            lz: 1,
            hly: 52,
            wgy: 122
          },{
            region: '区域8',
            num: 5,
            area: 2020,
            lz: 1,
            hly: 5,
            wgy: 122
          },]
        },
      ],
      scatter3DArr:[
        // {name: '洛阳市', value: [113.26436, 34.342896], num: 200000},
        // {name: '周口市', value: [113.649653,33.620357], num: 100},
        // {name: '1', value: [114.649653,33.620357], num: 500},
        // {name: '2', value: [112.649653,33.620357], num: 500},
        // {name: '3', value: [112.649653,32.620357], num: 500},
        // {name: '5', value: [114.649653,33.620357], num: 500},
        // {name: '6', value: [113.649653,32.620357], num: 4444},
        // {name: '7', value: [111.649653,33.920357], num: 333},
      ],
      options:{
        tooltip: {
          show: false,
          enterable: true,
          confine: true,
          // backgroundColor: 'transparent',
          // borderColor: 'transparent',
          // borderWidth: 0,
          // padding: 0,
          formatter: (params)=>{
            const _cur = this.dataList.find(item => item.city == params.name || item.county== params.name || item.regionid==params.name)|| ''
            return `${params.name}${this.title}:${_cur.count ||_cur.num || 0}`

          },
          color: '#fff', // 文字颜色
          fontSize: '12px', // 文字大小
          backgroundColor: this.show?'transparent':'rgba(5,4,41,0.79)',
          borderColor: this.show?'transparent':'#4986D5',
          borderWidth: this.show?0:1,
          borderRadius: 5,
          padding: this.show?0:10,
          textStyle:{
            color:'#fff',
            fontSize: 16, // 文字大小
          },
          rich: {
            city: {
              color: '#D9EAF5',
              borderRadius: 15,
              padding: [0, 0, 0, 10],
              fontFamily: 'Microsoft YaHei',
              fontWeight: 400,
              fontSize: '16px',
              fontStyle: 'italic'
            },
            num: {
              color: '#2EED32',
              borderRadius: 15,
              padding: [0, 0, 0, 10],
              fontFamily: 'Microsoft YaHei',
              fontWeight: 400,
              fontSize: '16px',
              fontStyle: 'italic'
            }
          }
        },
        geo: {
          map: 'henan',
          layoutCenter: ['50%', '50%'],
          layoutSize: '80%', // 地图大小，可以是百分比或者像素值
          selectedMode: 'single', // 地图高亮单选
          label: {
            show: true, // 是否显示名字
            color: '#fff', // 文字颜色
            fontSize: '0', // 文字大小
            formatter: (params)=>{
              const _cur = this.dataList.find(item =>item.county == params.name|| item.city == params.name) || ''
              return `{city|${params.name}:}{num|${_cur.leaderNum || 0}}`
            },
            backgroundColor: 'rgba(5,4,41,0.79)',
            borderColor: '#4986D5',
            borderWidth: 1,
            borderRadius: 5,
            padding: 3,
            rich: {
              city: {
                color: '#D9EAF5',
                borderRadius: 15,
                padding: [0, 0, 0, 10],
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic'
              },
              num: {
                color: '#2EED32',
                borderRadius: 15,
                padding: [0, 0, 0, 10],
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic'
              }
            }
          },
          realisticMaterial: {
            detailTexture: mapBg,  // 地图表面贴图
          },
          itemStyle:{

            borderWidth: 1, // 分界线wdith
            borderColor: '#fff', // 分界线颜色
            opacity: 0.82,
            borderType: "solid",
            areaColor: {
              type: "pattern",
              image: mapBg, //配置图片
              repeat: "repeat", //可选值repeat、no-repeat、repeat-x、repeat-y
            },
            color: 'rgba(24,144,255,1)', // 地图背景颜色
          },
          emphasis: {
            label: {
              show: true, // 是否显示高亮
              textStyle: {
                color: '#fff' // 高亮文字颜色
              }
            },
            itemStyle: {
              color: '#00baff', // 地图高亮颜色
              borderWidth: 10, // 分界线wdith
              areaColor:'#00baff',
              borderColor: '#6BECF5' // 分界线颜色
            }
          },

          left: '0',
          // top: '-20px',
          zoom: 1,
          // data: [],
          light: {
            main: {
              color: '#fff',
              intensity: 1.8,
              shadow: true,
              shadowQuality: 'high',
              alpha: 25, //
              beta: 20
            },
            ambient: {
              color: '#fff',
              intensity: 0.6
            }
          }
        },
        series: [
          {
            name: '地图',
            type: 'map',
            // registerMap注册的地图名称
            map: 'henan',
            // zoom: 1.2,
            zlevel: 0,
            layoutCenter: ['50%', '50%'],
            layoutSize: '80%', // 地图大小，可以是百分比或者像素值
            roam: false,
            label: {
              show: false,
              textStyle: {
                fontSize: 0,
                color: '#1d1d1d',
              },
            },
            itemStyle: {
              color: 'rgba(24,144,255,1)', // 地图背景颜色
              borderWidth: 0, // 分界线wdith
              borderColor: '#fff', // 分界线颜色
              opacity: 0,
              borderType: "dashed",
            },
            emphasis: {
              label: {
                show: false, // 是否显示高亮
                fontSize:0,
                textStyle: {
                  color: '#fff' // 高亮文字颜色
                }
              },
              itemStyle: {
                color: '#00baff', // 地图高亮颜色
                borderWidth: 0, // 分界线wdith
                opacity: 0.1,
                areaColor:'#00baff',
                borderColor: '#6BECF5' // 分界线颜色
              }
            },
            left: '0',
            zoom: 1,
            light: {
              main: {
                color: '#fff',
                intensity: 1,
                shadow: true,
                shadowQuality: 'high',
                alpha: 25, //
                beta: 20
              },
              ambient: {
                color: '#fff',
                intensity: 0.6
              }
            },
            data: [],
          },
          {
            name:'test',
            type: 'scatter',
            coordinateSystem: 'geo',
            geoIndex: 0,
            symbol: 'pin',
            symbolSize: 30,
            data: [],
            label: {
              show: false, // 是否显示名字
              color: '#fff', // 文字颜色
              fontSize: '12px', // 文字大小
              padding: 10,
            },
            itemStyle:{
              color: ({data}) => {
                if(data.type2 =='风景名胜区') {
                  return `#38A800`
                }else if(data.type2 =='自然保护区'){
                  return `#AAFFFF`
                }else if(data.type2 =='湿地公园'){
                  return `#3ECDBD`
                }else if(data.type2 =='地质公园'){
                  return `#00AAFF`
                }else if(data.type2 =='森林公园'){
                  return `#155815`
                }
                return `#ff0`
              }
            },
          },
        ],
      },
      option:{
        tooltip: {
          show: false, // 是否显示名字
          color: '#fff', // 文字颜色
          fontSize: '12px', // 文字大小
          formatter: (params)=>{
            const _cur = this.dataList.find(item =>item.county== params.name|| item.city == params.name )|| ''
            return `${params.name}:${_cur.leaderNum || 0}`
          },
          backgroundColor: 'rgba(5,4,41,0.79)',
          borderColor: '#4986D5',
          borderWidth: 1,
          borderRadius: 5,
          padding: 10,
          textStyle:{
            color:'#fff',
            fontSize: 16, // 文字大小
          },
          rich: {
            city: {
              color: '#D9EAF5',
              borderRadius: 15,
              padding: [0, 0, 0, 10],
              fontFamily: 'Microsoft YaHei',
              fontWeight: 400,
              fontSize: '16px',
              fontStyle: 'italic'
            },
            num: {
              color: '#2EED32',
              borderRadius: 15,
              padding: [0, 0, 0, 10],
              fontFamily: 'Microsoft YaHei',
              fontWeight: 400,
              fontSize: '16px',
              fontStyle: 'italic'
            }
          }
        },
        geo3D:{
          type: 'map3D',
          name: '地图',
          // 相对于父容器比例
          center: ['50%', '50%'],
          selectedMode: 'single', // 地图高亮单选
          regionHeight: 0, // 地图高度
          map: 'henan',
          viewControl: {
            // 缩放大小，数值越大，地图越小
            distance: 145,
            // 上下倾斜角度
            alpha: 90,
            // 左右倾斜角度
            beta: 0,
            rotateSensitivity: 0,
            autoRotate:false,
            zoomSensitivity: false,
          },
          label: {
            show: true, // 是否显示名字
            color: '#fff', // 文字颜色
            fontSize: '0', // 文字大小
            formatter: (params)=>{
              const _cur = this.dataList.find(item =>item.county == params.name|| item.city == params.name) || ''
              return `{city|${params.name}:}{num|${_cur.leaderNum || 0}}`
            },
            backgroundColor: 'rgba(5,4,41,0.79)',
            borderColor: '#4986D5',
            borderWidth: 0,
            borderRadius: 5,
            padding: 10,
            rich: {
              city: {
                color: '#D9EAF5',
                borderRadius: 15,
                padding: [0, 0, 0, 10],
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic'
              },
              num: {
                color: '#2EED32',
                borderRadius: 15,
                padding: [0, 0, 0, 10],
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic'
              }
            }
          },
          shading: "realistic",
          realisticMaterial: {
            detailTexture: mapBg,  // 地图表面贴图
          },
          itemStyle: {
            color: 'rgba(24,144,255,1)', // 地图背景颜色
            borderWidth: 1, // 分界线wdith
            borderColor: '#fff', // 分界线颜色
            opacity: 0.92,
            borderType: "dashed",
          },
          emphasis: {
            label: {
              show: true, // 是否显示高亮
              textStyle: {
                color: '#fff' // 高亮文字颜色
              }
            },
            itemStyle: {
              color: '#00baff', // 地图高亮颜色
              borderWidth: 10, // 分界线wdith
              borderColor: '#6BECF5' // 分界线颜色
            }
          },
          left: '0',
          // top: '-20px',
          zoom: 1,
          // data: [],
          light: {
            main: {
              color: '#fff',
              intensity: 1.8,
              shadow: true,
              shadowQuality: 'high',
              alpha: 25, //
              beta: 20
            },
            ambient: {
              color: '#fff',
              intensity: 0.6
            }
          }
        },
        series: [
          {
            zlevel: 5,
            type: 'map3D',
            name: '地图',
            // 相对于父容器比例
            center: ['50%', '50%'],
            selectedMode: 'single', // 地图高亮单选
            regionHeight: 6, // 地图高度
            map: 'henan',
            viewControl: {
              // 缩放大小，数值越大，地图越小
              distance: 145,
              // 上下倾斜角度
              alpha: 65,
              // 左右倾斜角度
              beta: 3,
              rotateSensitivity: 0,
              autoRotate:false,
              zoomSensitivity: false,
            },
            label: {
              show: false, // 是否显示名字
              color: '#fff', // 文字颜色
              fontSize: '12px', // 文字大小
            },
            itemStyle: {
              color: 'rgba(24,144,255,1)', // 地图背景颜色
              borderWidth: 0, // 分界线wdith
              borderColor: '#fff', // 分界线颜色
              opacity: 0,
              borderType: "dashed",
            },
            emphasis: {
              label: {
                show: false, // 是否显示高亮
                textStyle: {
                  color: '#fff' // 高亮文字颜色
                }
              },
              itemStyle: {
                color: '#00baff', // 地图高亮颜色
                borderWidth: 0, // 分界线wdith
                opacity: 0,
                borderColor: '#6BECF5' // 分界线颜色
              }
            },
            left: '0',
            // top: '-20px',
            zoom: 1,
            // data: [],
            light: {
              main: {
                color: '#fff',
                intensity: 1,
                shadow: true,
                shadowQuality: 'high',
                alpha: 25, //
                beta: 20
              },
              ambient: {
                color: '#fff',
                intensity: 0.6
              }
            }
          },
          {
            zlevel: 6,
            type: 'scatter3D',
            coordinateSystem: 'geo3D', // 指定坐标系为 3D 地理坐标系
            data: [],
            label: {
              show: true, // 是否显示名字
              color: '#fff', // 文字颜色
              fontSize: '12px', // 文字大小
              formatter: ({data})=>{
                if(data.num > 10000) {
                  return `{point2|}`
                }
                return `{point1|}`
              },
              padding: 10,
              rich: {
                point1: {
                  width: 40,
                  height: 40,
                  backgroundColor:{
                    image:point1
                  } ,
                  borderRadius: 15,
                  padding: [0, 0, 0, 10],
                  fontFamily: 'Microsoft YaHei',
                  fontWeight: 400,
                  fontSize: '16px',
                  fontStyle: 'italic'
                },
                point2: {
                  width: 40,
                  height: 40,
                  backgroundColor:{
                    image:point2
                  } ,
                  borderRadius: 15,
                  padding: [0, 0, 0, 10],
                  fontFamily: 'Microsoft YaHei',
                  fontWeight: 400,
                  fontSize: '16px',
                  fontStyle: 'italic'
                }
              }
            },
            emphasis: {
                label: {
                  show: true,
                  distance: 0,
                }
            },
            // 其他散点图配置
          },
        ]
      },
      chart: null,
      pointer: 0,
      timer: null,
      dataList:[]
    };
  },
  watch: {
  },
  computed: {
  },
  mounted() {
    this.$nextTick(() => {

    const chartDom = document.getElementById(this.id);
    this.chart = this.$echarts.init(chartDom);
    this.$echarts.registerMap("henan", mapJson);
    if(cityMap){
      Object.keys(cityMap).forEach(key => {
        this.$echarts.registerMap(key, cityMap[key]);
      })
    }
      this.draw({
            data: this.mapData,
            scatter3DArr: this.scatter3DArr
          })
    });
  },
  methods: {
    setData(e){
      this.dataList=e?e:[]
      this.draw({
        data: this.mapData,
        scatter3DArr: this.scatter3DArr
      })
    },
    initEvent() {
      this.chart.off('click')
      this.chart.on("click",{seriesName: '地图'}, ({data ={} }) => {
        if(data.code){
          this.options.series[0].map = data.code
          this.options.series[0].data = []
          this.options.geo.map = data.code
          this.chart.dispose();
          const chartDom = document.getElementById(this.id);
          this.chart = this.$echarts.init(chartDom);
          this.draw({
          })
        }else {
          if (this.options.series[0].map != 'henan') {
            this.options.series[0].map = 'henan'
            this.options.geo.map = 'henan'
            this.chart.dispose();
            const chartDom = document.getElementById(this.id);
            this.chart = this.$echarts.init(chartDom);
            this.draw({
              data: this.mapData,
              scatter3DArr: this.scatter3DArr
            })
          }
        }
        this.$emit('cityChange', data)
      });
      // this.chart.getZr().on('click', params => {
      //   if(params.target) {
      //     //画布非空白区
      //     return;
      //   } else {
      //     if(this.options.series[0].map != 'henan'){
      //       this.options.series[0].map = 'henan'
      //       this.options.geo.map = 'henan'
      //       this.chart.dispose();
      //       const chartDom = document.getElementById(this.id);
      //       this.chart = this.$echarts.init(chartDom);
      //       this.draw({
      //         data: this.mapData,
      //         scatter3DArr: this.scatter3DArr
      //       })
      //     }
      //     this.$emit('cityChange', {})
      //   }

      // });
    },
    draw(params = {}) {
      const {data =[], scatter3DArr = []} = params
      this.options.series[0].data = data;
      this.options.series[1].data = scatter3DArr
      this.chart.setOption(this.options, true);
      this.initEvent()
    },
  },
};
</script>
<style lang="scss" scoped>
.map-chart-container,
.map {
  position: relative;
  width: 100%;
  height: 100%;
}
.title{
  width: 149px;
  height: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 20px;
  color: #E7F8FB;
  line-height: 20px;
  position: absolute;
  left: 62px;
  top: 30px;
  padding-left: 13px;
  white-space: nowrap;
  &::before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 5px;
    height: 5px;
    background: #6DFF58;
    border-radius: 50%;
  }

}
.map-bg{
  width: 100%;
  height: 100%;
  // background: url('./images/map-bg.jpg') center center no-repeat;
}
.map-chart-container {
  position: relative;
}
 // 标记浮窗显示的内容
  .mark-item-container {
    // left: 420px;
    // top: 115px;
    // position: ;
    width: 284px;
    height: 368px;
    background: url('./images/dialog-bg.png') center center no-repeat;
    background-size: 100% 100%;
    display: block;
    padding: 30px 10px;
    .box-title{
      line-height: 1;
      text-align: left;
      position: absolute;
      left: 4px;
      top: 6px;
      width: 100%;
      height: 15px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 21px;
      white-space: nowrap;
    }
    .mark-item {
      margin-top: 5px;
      position: relative;
      width: 266px;
      height: 29px;
      line-height: 29px;
      display: flex;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #3DFDFF;
      padding: 0 10px 0 13px;
      .mark-item-label{
        text-align: left;
        width: 100px;
        white-space: nowrap;
        color: #3DFDFF;
        z-index: 2;
        position: relative;
      }
      .mark-item-val{
        flex: 1;
        color: #3DFDFF;
        z-index: 2;
        position: relative;
        text-align: right;
      }
      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: #152E83;
        opacity: 0.75;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }

    .left-top-corner,
    .left-bottom-corner,
    .right-top-corner,
    .right-bottom-corner {
      width: 11px;
      height: 11px;
      display: block;
      background-color: transparent;
      position: absolute;
      border: 1px solid #00e5ff;
    }
    .left-top-corner {
      left: 0;
      top: 0;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
    .left-bottom-corner {
      left: 0;
      bottom: 0;
      border-right-color: transparent;
      border-top-color: transparent;
    }
    .right-top-corner {
      right: 0;
      top: 0;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
    .right-bottom-corner {
      right: 0;
      bottom: 0;
      border-left-color: transparent;
      border-top-color: transparent;
    }
  }
</style>
