var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-chart-container"},[_c('div',{staticClass:"map",attrs:{"id":_vm.id}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],ref:"box",staticClass:"mark-item-container"},[_c('div',{staticClass:"box-title"},[_vm._v("XXX林长")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark-item"},[_c('div',{staticClass:"mark-item-label"},[_vm._v("编码：")]),_c('div',{staticClass:"mark-item-val"},[_vm._v(" FP41003 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark-item"},[_c('div',{staticClass:"mark-item-label"},[_vm._v("类型：")]),_c('div',{staticClass:"mark-item-val"},[_vm._v(" FP ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark-item"},[_c('div',{staticClass:"mark-item-label"},[_vm._v("级别：")]),_c('div',{staticClass:"mark-item-val"},[_vm._v(" 国家级 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark-item"},[_c('div',{staticClass:"mark-item-label"},[_vm._v("行政区划：")]),_c('div',{staticClass:"mark-item-val"},[_vm._v(" 河南平顶山汝州市 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark-item"},[_c('div',{staticClass:"mark-item-label"},[_vm._v("面积（公顷）：")]),_c('div',{staticClass:"mark-item-val"},[_vm._v(" 5375.83 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark-item"},[_c('div',{staticClass:"mark-item-label"},[_vm._v("批复面积：")]),_c('div',{staticClass:"mark-item-val"},[_vm._v(" 4900.37 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark-item"},[_c('div',{staticClass:"mark-item-label"},[_vm._v("批复时间：")]),_c('div',{staticClass:"mark-item-val"},[_vm._v(" - ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark-item"},[_c('div',{staticClass:"mark-item-label"},[_vm._v("批准文号：")]),_c('div',{staticClass:"mark-item-val"},[_vm._v(" 林造批字【1992 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mark-item"},[_c('div',{staticClass:"mark-item-label"},[_vm._v("调整批复文：")]),_c('div',{staticClass:"mark-item-val"},[_vm._v(" - ")])])
}]

export { render, staticRenderFns }