<template>
  <div class="large-screen-container flex flex-col">
    <Header title="河南智慧林业综合平台"></Header>
    <div class="body flex">
      <div class="content">
        <div class="left">
          <div class="left-top">
            <div class="left-top-title">
              河南省林长制人员统计（个）
            </div>
            <div class="section">
              <div class="hqxx-section">
                <div class="hqxx-section-label">
                  <i class="hqxx-section-label-icon"></i>
                  <span class="hqxx-section-label-title">全省林长人数</span>
                </div>
                <div class="hqxx-sction-info">
                  <div class="hqxx-section-info-cell" v-for="(item,i) in drhqsArr" :key="i">
                    {{item != '-' ? item :''}}
                  </div>
                </div>
              </div>
              <div class="hqxx-section tree-section">
                <div class="tree-card">
                  <div class="tree-card-top">
                    护林员
                  </div>
                  <div class="tree-card-num">
                    {{getLeaderObj?getLeaderObj.rorestRangers:0}}
                  </div>
                </div>
                <div class="tree-card">
                  <div class="tree-card-top">
                    网格员
                  </div>
                  <div class="tree-card-num">
                    {{getLeaderObj?getLeaderObj.supervisor:0}}
                  </div>
                </div>
              </div>
            </div>

            <div class="line-section" style="padding:0  10px 0 20px;margin-top:0;" >
              <div class="table-wrap table-section" >
                <table>
                  <thead>
                  <tr style="text-align: center">
                    <th>姓名</th>
                    <!--                      <th>林场个数</th>-->
                    <!--                      <th>林场面积</th>-->
                    <th>党政职务</th>
                    <th>林长类别</th>
                    <th>责任区域</th>
                  </tr>
                  </thead>
                  <tbody>
<!--                  @click="getTableData(item)"-->
                  <tr v-for="(item,i) in woodsLeaderUserList" :key="i" @mouseover="getTableData(item)" @mouseout="dialogVisible=false"  style="text-align: center;line-height: 24px;cursor: pointer">
                    <td>{{item.name}}</td>
                    <!--                      <td>{{item.num}}</td>-->
                    <!--                      <td>{{item.area}}</td>-->
                    <td>{{item.partyAffairs}}</td>
                    <td>{{item.woodsLeaderType}}</td>
                    <td>{{item.responsibilityArea}}</td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
<!--          <div class="line-section">-->
<!--            <div class="section-title">-->
<!--              近5年林长数量-->
<!--            </div>-->
<!--            <div class="section-content">-->
<!--              <line-chart :datas="lineDatas"></line-chart>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="line-section">-->
<!--            <div class="section-title">-->
<!--              近5年护林员数量-->
<!--            </div>-->
<!--            <div class="section-content">-->
<!--              <line-chart :datas="lineDatas2" color="#4986D5"></line-chart>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="line-section">-->
<!--            <div class="section-title">-->
<!--              近5年网格员数量-->
<!--            </div>-->
<!--            <div class="section-content">-->
<!--              <line-chart :datas="lineDatas3" color="#CE6E34"></line-chart>-->
<!--            </div>-->
<!--          </div>-->
          </div>
        </div>
        <div class="center">
          <MapChart ref="map" @cityChange="cityChange"></MapChart>
        </div>
        <div class="right">
          <div class="right-top">
            <div class="right-top-title">
              {{cityTitle}}
            </div>
            <div class="right-top-body">
              <div class="right-top-body-top">
                <div class="right-top-body-top-item">
                  <div class="label">林长</div>
                  <div class="num">{{cityLeaderObj?cityLeaderObj.leaderNum:0}}</div>
                </div>
                <div class="right-top-body-top-item">
                  <div class="label">护林员</div>
                  <div class="num">{{cityLeaderObj?cityLeaderObj.rorestRangers:0}}</div>
                </div>
                <div class="right-top-body-top-item">
                  <div class="label">网格员</div>
                  <div class="num">{{cityLeaderObj&&cityLeaderObj.supervisor?cityLeaderObj.supervisor:0}}</div>
                </div>
              </div>
<!--              <div class="right-top-body-chart">-->
<!--                <BarChart :datas="barDatas"></BarChart>-->
<!--              </div>-->
              <div class="table-wrap">
                <table>
                  <thead>
                    <tr>
                      <th>区域</th>
<!--                      <th>林场个数</th>-->
<!--                      <th>林场面积</th>-->
                      <th>林长</th>
                      <th>护林员</th>
                      <th>网格员</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item,i) in leaderList" :key="i">
                      <td>{{item.county || item.city}}</td>
<!--                      <td>{{item.num}}</td>-->
<!--                      <td>{{item.area}}</td>-->
                      <td>{{item.leaderNum?item.leaderNum:0}}</td>
                      <td>{{item.rorestRangers?item.rorestRangers:0}}</td>
                      <td>{{item.supervisor?item.supervisor:0}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom ">
        <Navigation></Navigation>
      </div>
    </div>
<!--    <el-dialog-->
<!--            title="人员详细信息"-->
<!--            :visible.sync="dialogVisible"-->
<!--            width="30%">-->
<!--      <el-descriptions>-->
<!--        <el-descriptions-item label="姓名">{{tableRowData.name==null?'暂无':tableRowData.name}}</el-descriptions-item>-->
<!--        <el-descriptions-item label="党政职务">{{tableRowData.partyAffairs==null?'暂无':tableRowData.partyAffairs}}</el-descriptions-item>-->
<!--        <el-descriptions-item label="林长类别">{{tableRowData.woodsLeaderType==null?'暂无':tableRowData.woodsLeaderType}}</el-descriptions-item>-->
<!--        <el-descriptions-item label="责任区域" :span="2">{{tableRowData.responsibilityArea==null?'暂无':tableRowData.responsibilityArea}}</el-descriptions-item>-->
<!--        <el-descriptions-item label="联系点">{{tableRowData.contactLocation==null?'暂无':tableRowData.contactLocation}}</el-descriptions-item>-->
<!--        <el-descriptions-item label="工作保障单位" :span="3">{{tableRowData.company==null?'暂无':tableRowData.company}}</el-descriptions-item>-->
<!--        <el-descriptions-item label="网格员">{{tableRowData.regulator==null?'暂无':tableRowData.regulator}}</el-descriptions-item>-->
<!--        <el-descriptions-item label="网格员职责">{{tableRowData.regulatorPost==null?'暂无':tableRowData.regulatorPost}}</el-descriptions-item>-->
<!--        <el-descriptions-item label="监管区域">{{tableRowData.regulatoryRrea==null?'暂无':tableRowData.regulatoryRrea}}</el-descriptions-item>-->
<!--        <el-descriptions-item label="护林员数量">{{tableRowData.rorestRangers==null?'暂无':tableRowData.rorestRangers}}</el-descriptions-item>-->
<!--      </el-descriptions>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--    <el-button @click="dialogVisible = false">关闭</el-button>-->
<!--  </span>-->
<!--    </el-dialog>-->
    <div :class="`mark-point-box`" v-show="dialogVisible">

      <div class="mark-item-container" ref="box">
        <div class="box-title">人员详细信息</div>
        <div class="mark-item">
          <div class="mark-item-label">姓名：</div>
          <div class="mark-item-val">
            {{tableRowData.name==null?'暂无':tableRowData.name}}
          </div>
        </div>
        <div class="mark-item">
          <div class="mark-item-label">党政职务：</div>
          <div class="mark-item-val">
            {{tableRowData.partyAffairs==null?'暂无':tableRowData.partyAffairs}}
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">林长类别：</div>
          <div class="mark-item-val">
            {{tableRowData.woodsLeaderType==null?'暂无':tableRowData.woodsLeaderType}}
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">责任区域：</div>
          <div class="mark-item-val">
            {{tableRowData.responsibilityArea==null?'暂无':tableRowData.responsibilityArea}}
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">联系点：</div>
          <div class="mark-item-val">
            {{tableRowData.contactLocation==null?'暂无':tableRowData.contactLocation}}
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">工作保障单位：</div>
          <div class="mark-item-val">
            {{tableRowData.company==null?'暂无':tableRowData.company}}
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">网格员：</div>
          <div class="mark-item-val">
            {{tableRowData.regulator==null?'暂无':tableRowData.regulator}}
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">网格员职责：</div>
          <div class="mark-item-val">
            {{tableRowData.regulatorPost==null?'暂无':tableRowData.regulatorPost}}
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">监管区域：</div>
          <div class="mark-item-val">
            {{tableRowData.regulatoryRrea==null?'暂无':tableRowData.regulatoryRrea}}
          </div>
        </div>

        <div class="mark-item">
          <div class="mark-item-label">护林员数量：</div>
          <div class="mark-item-val">
            {{tableRowData.rorestRangers==null?'暂无':tableRowData.rorestRangers}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flexs from "@/mixins/flexs.js";
import BarChart from "./components/bar-chart";
import MapChart from "./components/map-chart";
import Header from '../../components/header'
import Navigation from '../../components/navigation'
import LineChart from './components/line-chart';
import { ScatterChart } from 'echarts';
import {
  getLeaderNum,
  woodsLeaderUser
} from '@/api/integratedPlatform'
export default {
  data() {
    return {
      dialogVisible:false,
      getLeaderObj:{},
      drhqs: '1586',
      cityInfo: {
        name: '平顶山市',
        lz: 21,
        hly: 45,
        wgy: 121
      },
      barDatas:{
        legend: ["林场个数", "林场面积"],
        xaxis: ["宝丰县","鲁山县","郊县","叶县"],
        dataArr: [
          [380, 270, 180, 170],
          [280, 180, 150, 140],
        ]
      },
      lineDatas:{
        xaxis: [ "2019年", "2020年", "2021年", "2022年", "2023年"],
        dataArr: [
          [ 200, 301, 260, 370, 380],
        ]
      },


      lineDatas2:{
        xaxis: [ "2019年", "2020年", "2021年", "2022年", "2023年"],
        dataArr: [
          [ 200, 320, 201, 330, 380],
        ]
      },


      lineDatas3:{
        xaxis: [ "2019年", "2020年", "2021年", "2022年", "2023年"],
        dataArr: [
          [ 100, 212, 360, 350, 380],
        ]
      },
      tableData: [{
        region: '区域1',
        num: 5,
        area: 200,
        lz: 1,
        hly: 5,
        wgy: 12
      },{
        region: '区域2',
        num: 3,
        area: 400,
        lz: 2,
        hly: 5,
        wgy: 12
      },{
        region: '区域3',
        num: 9,
        area: 1200,
        lz: 2,
        hly: 5,
        wgy: 121
      },{
        region: '区域4',
        num: 2,
        area: 100,
        lz: 1,
        hly: 9,
        wgy: 12
      },{
        region: '区域5',
        num: 5,
        area: 200,
        lz: 1,
        hly: 51,
        wgy: 12
      },{
        region: '区域6',
        num: 5,
        area: 200,
        lz: 1,
        hly: 5,
        wgy: 121
      },{
        region: '区域7',
        num: 5,
        area: 200,
        lz: 1,
        hly: 52,
        wgy: 122
      },{
        region: '区域8',
        num: 5,
        area: 2020,
        lz: 1,
        hly: 5,
        wgy: 122
      },],
      cityRow:{},
      cityLeaderObj:{},
      cityTitle:'河南省',
      leaderList:[],
      dataList:[],
      woodsLeaderUserList:[],
      tableRowData:{}
    };
  },
  components: {
    MapChart,
    Header,
    Navigation,
    LineChart,
    BarChart
  },
  computed: {
    drhqsArr(){
      return this.convertToArr(this.drhqs)
    },
  },
  mounted(){
    this.getLeaderNumFun(1)
    this.woodsLeaderUserFun()
  },
  methods: {
    //获取表格信息
    getTableData(e){
      this.tableRowData=e
      this.dialogVisible=true
    },
    //林长制人员
    woodsLeaderUserFun(){
      let data={
        pageNum:1,
        pageSize:100,
        city:this.cityRow.name?this.cityRow.name:'',
        county:''
      }
      woodsLeaderUser(data).then(res=>{
        if(res.code==200){
          this.woodsLeaderUserList=res.rows
        }
      })
    },
    //  林长制
    getLeaderNumFun(type){
      let data={
        cityName:this.cityRow.name?this.cityRow.name:'',
      }
      getLeaderNum(data).then(res=>{
        if(res.code==200){
          if(type==1){
            this.getLeaderObj=res.data.summary
            this.dataList=res.data.list
            this.$refs.map.setData(this.dataList)
            this.drhqs=this.getLeaderObj?this.getLeaderObj.leaderNum.toString():'0'
          }
          this.cityLeaderObj=res.data.summary
          this.leaderList=[]
          this.leaderList=res.data.list

        }
      })
    },
    cityChange(data){
      if(data){
        this.cityRow=data
        this.getLeaderNumFun(1)
        this.woodsLeaderUserFun()
        this.cityTitle = data!=undefined?data.name:''
      }else{
        this.cityRow={}
        this.getLeaderNumFun(1)
        this.woodsLeaderUserFun()
        this.cityTitle = ''
      }
    },
    convertToArr(_str){
      const maxLen = 8
      let _arr = Array.apply(null, Array(10)).map(function() { return '-'; });
      const _beginIndex = maxLen - _str.length
      _arr.splice(_beginIndex)
      _arr = [..._arr, ..._str.split('')]
      return _arr
    },
  },
  mixins: [flexs],
};
</script>
<style lang="scss">
@import "../../assets/css/index.scss";
</style>
<style lang="scss" scoped>
.large-screen-container {
  height: 100%;
  overflow: hidden;
  background: url('../../assets/images/ls-bg.png') center center no-repeat;
  background-size: 100% 100%;
  padding: 0 35px 39px 59px;
  position: relative;


  .body{
    height: calc(100% - 102px);
    width: calc(100% - 112px);
    position: absolute;
    left: 53px;
    top: 80px;
    display: flex;
    flex-direction: column;
    .content{
      flex: 1;
      display: flex;
      .left{
				text-align: left;
        width: 437px;
        .left-top{
					height: 100%;
          background: url('./images/left-top-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .left-top-title{
            left: 55px;
            top: 3px;
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
					.hqxx-section{
						margin-top: 13px;
						margin-left: 35px;
						margin-right: 20px;
            margin-bottom: 20px;

						.hqxx-section-label{
							display: flex;
              align-items: center;
							.hqxx-section-label-icon{
								display: block;
								width: 24px;
								height: 24px;
								margin-right: 14px;
								background: url("./images/people.png") center center no-repeat;
								background-size: 100% 100%;
							}
							.hqxx-section-label-title{
								flex: 1;
								height: 17px;
								line-height: 1;
								font-family: Microsoft YaHei;
								font-weight: 400;
								font-size: 16px;
								color: #FFFFFF;
								text-align: left;
							}
						}
            .hqxx-sction-info{
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
              .hqxx-section-info-cell{
                width: 30px;
                height: 43px;
                background: #0D1732;
                border: 1px solid #2D80EC;
                line-height: 43px;
                font-family: PangMenZhengDao;
                font-weight: 400;
                font-size: 30px;
                color: #69FFFA;
                text-align: center;
              }
            }
            &.tree-section{
              display: flex;
              justify-content: space-between;
              .tree-card{
                width: 182px;
                height: 71px;
                background: url('./images/tree-card-bg.png') center center no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                text-align: center;
                .tree-card-top{
                  height: 17px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #FFFFFF;
                  line-height: 17px;
                }
                .tree-card-num{
                  height: 17px;
                  font-family: PangMenZhengDao;
                  font-weight: 400;
                  font-size: 26px;
                  color: #69FFFA;
                  line-height: 17px;
                }
              }
            }
					}
          .line-section{
            margin-top: 10px;
            .section-title{
              margin-left: 25px;
              height: 17px;
              line-height: 1;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 16px;
              color: #fefefe;
              text-align: left;
            }
            .section-content{
              width: 100%;
              height: 195px;
            }
          }
				}
      }
      .right{
        width: 437px;
        .right-top{
          margin-top: 10px;
          .right-top-title{
            height: 40px;
            line-height: 40px;
            padding-left: 22px;
            font-family: Adobe Heiti Std;
            font-weight: normal;
            font-size: 22px;
            text-align: left;
            color: #FFFFFF;
            background: url('./images/right-top-title.png') left center no-repeat;
            background-size: 317px 100%;
          }
          .right-top-body{
            padding: 12px;
            background: url('./images/right-top-bg.png') center center no-repeat;
            background-size: 100% 100%;
            .right-top-body-top{
              display: flex;
              height: 94px;
              background: url('./images/right-top-item.png') center center no-repeat;
              background-size: 100% 100%;
              justify-content: space-evenly;
              align-items: center;
              .right-top-body-top-item{
                width: 100px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                height: 100%;
                &:last-of-type::after{
                  display: none;
                }
                &::after{
                  content: '';
                  display: block;
                  width: 1px;
                  height: 51px;
                  background: linear-gradient(-90deg, #3553A8 0%, rgba(19,61,168,0.06) 100%);
                  border: 1px solid;
                  border-image: linear-gradient(0deg, #015EEA, #00C0FA) 10 10;
                  position: absolute;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  margin: auto;
                  transform: scaleX(0.5);
                }
                .label{
                  height: 16px;
                  line-height: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #FFFFFF;
                }
                .num{
                  height: 17px;
                  font-family: PangMenZhengDao;
                  font-weight: 400;
                  font-size: 26px;
                  color: #69FFFA;
                  line-height: 17px;
                }
              }
            }
            .right-top-body-chart{
              height: 247px;
            }
          }
        }
      }
      .center{
        flex: 1;
      }
    }
    .bottom{
      height: 50px;
      margin-top: 13px;
    }
  }
  .table-wrap{
    margin-top: 16px;
    table{
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 2px;
      thead{
        line-height: 36px;
        height: 36px;
        background: #1A337C;
        th{
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 14px;
          color: #D7E1FD;
        }
      }
      tbody{
        tr{
          margin-top: 2px;
          height: 36px;
          line-height: 36px;
          background: rgba(26,51,124,0.3);
        }
      }
    }
    table {
      width: 100%;
      max-height: 470px; /* 设置最大高度 */
      overflow-y: auto;   /* 启用垂直滚动条 */
      display: block;     /* 确保表格作为块级元素显示 */
    }

    tbody {
      display: block;     /* 使tbody作为块级元素显示 */
      height: 100%;      /* 设置tbody的高度为table的100% */
      /*overflow-y: scroll; !* 启用垂直滚动条 *!*/
    }

    thead, tbody tr {
      display: table;    /* 让thead和tbody中的元素像表格一样显示 */
      width: 100%;       /* 设置宽度 */
      table-layout: fixed; /* 固定列宽 */
    }
  }
}
  .table-section{

    tbody{
      tr{
        margin-top: 2px;
        padding:6px 0;
        background: rgba(26,51,124,0.3);
      }
    }
    table {
      width: 100%;
      max-height: 650px !important; /* 设置最大高度 */
      overflow-y: auto;   /* 启用垂直滚动条 */
      display: block;     /* 确保表格作为块级元素显示 */
    }
  }
/* 自定义滚动条整体样式 */
::-webkit-scrollbar {
  width: 0px; /* 滚动条宽度 */
  background-color: #f9f9f9; /* 滚动条背景色 */
}

/* 自定义滚动条滑块样式 */
::-webkit-scrollbar-thumb {
  background-color: #9E9E9E; /* 滑块背景色 */
  border-radius: 10px; /* 滑块圆角 */
}

/* 鼠标悬停时滑块样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: #757575;
}

// 标记浮窗显示的内容
.mark-item-container {
  // left: 420px;
  // top: 115px;
  // position: ;
  position: fixed;
  top:20%;
  left:30%;
  width: 350px;
  min-height: 368px;
  background: url('../investigationMonitor/images/dialog-bg.png') center center no-repeat;
  background-size: 100% 100%;
  display: block;
  padding: 30px 10px;
  .box-title{
    line-height: 1;
    text-align: left;
    position: absolute;
    left: 6px;
    top: 10px;
    width: 100%;
    height: 15px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 15px;
    white-space: nowrap;
  }
  .mark-item {
    margin-top: 5px;
    position: relative;
    width: 100%;
    /*height: 40px;*/
    /*line-height: 40px;*/
    display: flex;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    /*padding: 0 10px 0 13px;*/
    padding:10px 5px;
    .mark-item-label{
      text-align: left;
      width: 100px;
      white-space: nowrap;
      color: #fff;
      z-index: 2;
      position: relative;
    }
    .mark-item-val{
      flex: 1;
      color: #fff;
      z-index: 2;
      position: relative;
      text-align: right;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: #152E83;
      opacity: 0.75;
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  .left-top-corner,
  .left-bottom-corner,
  .right-top-corner,
  .right-bottom-corner {
    width: 11px;
    height: 11px;
    display: block;
    background-color: transparent;
    position: absolute;
    border: 1px solid #00e5ff;
  }
  .left-top-corner {
    left: 0;
    top: 0;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }
  .left-bottom-corner {
    left: 0;
    bottom: 0;
    border-right-color: transparent;
    border-top-color: transparent;
  }
  .right-top-corner {
    right: 0;
    top: 0;
    border-left-color: transparent;
    border-bottom-color: transparent;
  }
  .right-bottom-corner {
    right: 0;
    bottom: 0;
    border-left-color: transparent;
    border-top-color: transparent;
  }
}
</style>
